var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "option-list columns is-centered is-multiline is-mobile" },
    _vm._l(_vm.options, function (option) {
      return _c(
        "li",
        {
          key: option.id,
          staticClass:
            "option-box column is-half-mobile is-6-tablet is-4-desktop is-3-widescreen",
          attrs: { item: option },
        },
        [
          _c("label", { attrs: { for: option.id } }, [
            _vm.multiselect
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectedId,
                      expression: "selectedId",
                    },
                  ],
                  attrs: { type: "checkbox", name: "options", id: option.id },
                  domProps: {
                    checked: _vm.selected,
                    value: option.id,
                    checked: Array.isArray(_vm.selectedId)
                      ? _vm._i(_vm.selectedId, option.id) > -1
                      : _vm.selectedId,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.selectedId,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = option.id,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.selectedId = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.selectedId = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.selectedId = $$c
                      }
                    },
                  },
                })
              : _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectedId,
                      expression: "selectedId",
                    },
                  ],
                  attrs: { type: "radio", name: "options", id: option.id },
                  domProps: {
                    checked: _vm.selected,
                    value: option.id,
                    checked: _vm._q(_vm.selectedId, option.id),
                  },
                  on: {
                    change: function ($event) {
                      _vm.selectedId = option.id
                    },
                  },
                }),
            _c("div", { staticClass: "image-container" }, [
              _c("div", { staticClass: "check" }, [
                _c(
                  "svg",
                  {
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      viewBox: "0 0 512 512",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        d: "M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z",
                        fill: "currentColor",
                      },
                    }),
                  ]
                ),
              ]),
              _c("img", {
                attrs: {
                  src: require("@/assets/" +
                    option.imageUrl +
                    "/" +
                    option.artNumber +
                    ".png"),
                  alt: option.name,
                },
              }),
            ]),
            _c(
              "h2",
              {
                staticClass:
                  "has-text-weight-bold is-size-7 is-size-8-tablet is-uppercase has-text-dark margin-xxxsmall-top",
              },
              [_vm._v(_vm._s(_vm.$t(`label.${option.name}`)))]
            ),
            option.subheading1 !== ""
              ? _c(
                  "div",
                  {
                    staticClass:
                      "has-text-weight-bold is-size-7 is-size-8-tablet is-uppercase has-text-dark",
                  },
                  [_vm._v(_vm._s(option.subheading1))]
                )
              : _vm._e(),
            option.subheading2 !== ""
              ? _c(
                  "div",
                  {
                    staticClass:
                      "has-text-weight-bold is-size-7 is-size-8-tablet is-uppercase has-text-light",
                  },
                  [_vm._v(_vm._s(option.subheading2))]
                )
              : _vm._e(),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }