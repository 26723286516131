<template>
  <ul class="option-list columns is-centered is-multiline is-mobile">
    <li 
      v-for="option in options" 
      :key="option.id" 
      :item="option"
      class="option-box column is-half-mobile is-6-tablet is-4-desktop is-3-widescreen"
    >
      <label v-bind:for="option.id">
        <input v-if="multiselect" type="checkbox" name="options" :checked="selected" :id="option.id" :value="option.id" v-model="selectedId">
        <input v-else type="radio" name="options" :checked="selected" :id="option.id" :value="option.id" v-model="selectedId">
        <div class="image-container">
          <div class="check">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z" fill="currentColor" /></svg>
          </div>
          <!-- <img v-bind:src="option.imageUrl" v-bind:alt="option.name"> -->
          <img v-bind:src="require('@/assets/' + option.imageUrl + '/' + option.artNumber + '.png')" v-bind:alt="option.name">
          <!-- <img v-bind:src="require('@/assets/'+option.type+'/'+option.artNumber+'.png')" v-bind:alt="option.name"> -->
          <!-- <img v-bind:src="getImgUrl(option)" v-bind:alt="option.name"> -->
        </div>
        <h2 class="has-text-weight-bold is-size-7 is-size-8-tablet is-uppercase has-text-dark margin-xxxsmall-top">{{ $t(`label.${option.name}`) }}</h2>
        <div v-if="option.subheading1 !== ''" class="has-text-weight-bold is-size-7 is-size-8-tablet is-uppercase has-text-dark">{{ option.subheading1 }}</div>
        <div v-if="option.subheading2 !== ''" class="has-text-weight-bold is-size-7 is-size-8-tablet is-uppercase has-text-light">{{ option.subheading2 }}</div>
      </label>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'option-item',
  props: ['options', 'multiselect', 'selection'],
  data () {
    return {
      selected: false,
      selectedId: []
    }
  },
  computed: {
  },
  methods: {
    getImgUrl(option) {
      require('@/assets/'.concat(option.type,'/',option.artNumber,'.png'))
    }
  },
  watch: {
    selectedId (val) {
      this.$store.dispatch('updateValue', {name: this.selection, value: val})
    },
  },
  mounted() {
      let predefinedValue = JSON.parse(JSON.stringify(this.$store.getters.getSelectedValue(this.selection)))
      predefinedValue ? this.selectedId = predefinedValue : this.selectedId = []
  },
}
</script>

<style lang="sass">
@import "@/assets/styles/_variables.sass"
@import "@/assets/styles/_mixins.sass"
.option-box
  &.disabled
    opacity: 0.75

  label
    display: flex
    flex-direction: column
    flex: 0 1 auto
    input
      opacity: 0
      pointer-events: none
      width: 0
      height: 0

    .image-container
      border: 3px solid #EEEEEE
      padding: 6px
      position: relative
      background: #fff
      transition: border-color 175ms linear
      height: 100%
      &:hover
        border-color: $light
        cursor: pointer
      img
        display: block
        margin: 0 auto

    .check
      display: flex
      justify-content: center
      align-items: center
      color: #fff
      background: $red
      position: absolute
      top: -3px
      right: -3px
      padding: 6px
      opacity: 0
      svg
        width: 11px
        height: 11px

    input:checked ~ .image-container
      border-color: $red
      .check
        opacity: 1
</style>
