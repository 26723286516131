var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "columns is-centered" }, [
        _c("div", { staticClass: "column is-full" }, [
          _c("div", { staticClass: "help-text" }, [
            _vm._v(
              "\n        " + _vm._s(_vm.$t("roof.help_text")) + "\n      "
            ),
          ]),
        ]),
      ]),
      _c("stepNav", {
        attrs: {
          name: _vm.$t("roof.pick_rooftype"),
          validStep: _vm.isStepComplete(["rooftype"]),
        },
      }),
      _c("optionItems", {
        attrs: {
          options: _vm.options,
          multiselect: false,
          selection: "rooftype",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }